import React, {useState,useEffect}  from "react"
import SEO from "../components/seo"
import Image from "../components/image"
import firebase from "../components/firebase"
import "../assets/admin.css"
import styled from "styled-components"
import { navigate } from "gatsby-link";

const GetAnimations = () => {
  const [animations, setAnimations] = useState([]); //useState() hook, sets initial state to an empty array
  
  useEffect(() => {
    const unsubscribe = firebase
    .firestore()
    .collection("animations")
    .onSnapshot(snapshot => {
          const listItems = snapshot.docs.map(doc => ({
              id: doc.id,
            ...doc.data(),
          }));
          setAnimations(listItems)
        });
        return () => unsubscribe();
      },[]);
      
    return animations;
};

const GetEvents = () => {
  const [events, setEvents] = useState([]); //useState() hook, sets initial state to an empty array
  
  useEffect(() => {
    const unsubscribe = firebase
    .firestore()
    .collection("events")
    .onSnapshot(snapshot => {
          const listItems = snapshot.docs.map(doc => ({
              id: doc.id,
            ...doc.data(),
          }));
          setEvents(listItems)
        });
        return () => unsubscribe();
      },[]);
    return events;
};

const GetTestimonial = () => {

  const [testimonials, setTestimonials] = useState([]); //useState() hook, sets initial state to an empty array
  
  useEffect(() => {
    const unsubscribe = firebase
    .firestore()
    .collection("testimonials")
    .onSnapshot(snapshot => {
          const listItems = snapshot.docs.map(doc => ({
              id: doc.id,
            ...doc.data(),
          }));
          setTestimonials(listItems)
        });
        return () => unsubscribe();
      },[]);
    return testimonials;
};
const GetMenus = () => {

  const [customMenus, setCustomMenus] = useState([]); //useState() hook, sets initial state to an empty array
  
  useEffect(() => {
    const unsubscribe = firebase
    .firestore()
    .collection("customMenus")
    .onSnapshot(snapshot => {
          const listItems = snapshot.docs.map(doc => ({
              id: doc.id,
            ...doc.data(),
          }));
          setCustomMenus(listItems)
        });
        return () => unsubscribe();
      },[]);
    return customMenus;
};

const GetRecipes = () => {
  const [recipes, setRecipes] = useState([]); //useState() hook, sets initial state to an empty array
  
  useEffect(() => {
    const unsubscribe = firebase
    .firestore()
    .collection("recipes")
    .onSnapshot(snapshot => {
          const listItems = snapshot.docs.map(doc => ({
              id: doc.id,
            ...doc.data(),
          }));
          setRecipes(listItems)
        });
        return () => unsubscribe();
      },[]);
    return recipes;
};

const GetHomeImageUrls = () => {

  const [homeImages, setHomeMenuImagesUrl] = useState([]);
  
  useEffect(() => {

    const unsubscribe = firebase

    .firestore()
    .collection("homeImages")
    .onSnapshot(snapshot => {
          const listItems = snapshot.docs.map(doc => ({
              id: doc.id,
            ...doc.data(),
          }));
          setHomeMenuImagesUrl(listItems)
        });
        return () => unsubscribe();
      },[]);

    return homeImages;
};


const Admin = () => {

  const initialRecipeState = { name: "", ingredients: [""], directions: [""], video_link: "", image_url: ""}


  const [homeMenuImageUrl, setHomeMenuImagesUrl] = useState("");  

  const [dropDown, setDropDown] = useState({
    id: null,
    height:"auto"
  });

  const [showAddNewItem, setShowAddNewItem] = useState(false);
  const [newItemName, setNewItemName] = useState('');

  
  const [eventName, setEventName] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [eventCity, setEventCity] = useState("");
  const [eventAdditionalComments, setEventAdditionalComments] = useState("");

  const [editingItemId, setEditingItemId] = useState(null);
  const [editedItemName, setEditedItemName] = useState('');
  const [editingItemIndex, setEditingItemIndex] = useState(null);
  const [editedName, setEditedName] = useState('');
  
  const [customMenus, setCustomMenus] = useState([]);

  const [newMenuItem, setNewMenuItem] = useState({ name: '', notes: '', type: '' });
  const [newCustomMenu, setNewCustomMenu] = useState({ title: '', menu: [] });

  const [recipe, setRecipe] = useState(initialRecipeState);

  const [menuToDelete, setMenuToDelete] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  
  const [testimonialUsername, setTestimonialUsername] = useState("");
  const [testimonial, setTestimonial] = useState("");
  const [testimonialDate, setTestimonialDate] = useState("");
  
  const [item, setItem] = useState({});

  const setCss = (e) => {
    if(e == dropDown.id){
      setDropDown({...dropDown,id:null})    
    }else{
      setDropDown({...dropDown,id:e})
    }
  }
  const handleEditItem = (Id, type, menuIndex) => {
    setEditingItemId(`${Id}-${type}-${menuIndex}`);
  };

  const handleStartEdit = (itemIndex, currentName) => {
    setEditingItemIndex(itemIndex);
    setEditedName(currentName);
  };

  const handleAddNewItemToggle = () => {
    setShowAddNewItem(!showAddNewItem);
    setNewItemName(''); // Reset the input when toggling
  };

  const onAnimationTextChange = e => {
    setItem({id:item.id,animation:e.target.value})
  };

  const editAnimationTextItem = (item) => {
    setItem(item)
  };
  
  const deleteMenu = menuId => {
    firebase.firestore().collection("customMenus").doc(menuId).delete().then(() => {
      console.log("Menu successfully deleted!");
      setMenuToDelete(null);
      setShowDeleteConfirmation(false);
    }).catch(error => {
      console.error("Error removing menu: ", error);
    });
  };

  const onTextAnimationSubmit = e => {
    
    e.preventDefault();
    firebase
    .firestore()
    .collection("animations")
    .doc(item.id)
    .update({animation:item.animation});

  };

  const addEvent = e => {

    e.preventDefault();    
    firebase
    .firestore()
    .collection("events")
    .add({
      eventName:eventName,
      date:eventDate,
      where:eventLocation,
      city:eventCity,
      additionalComment: eventAdditionalComments
    })
    .then(() => setEventName(""), setEventLocation(""), setEventDate(""),setEventAdditionalComments(""));

  };

  const addTestimonials = e => {

    e.preventDefault();    

    firebase
    .firestore()
    .collection("testimonials")
    .add({
      username:testimonialUsername,
      date:testimonialDate,
      testimonial:testimonial,
      publish: true,
    })
    .then(() => setTestimonialUsername(""), setTestimonialDate(""), setTestimonial(""));
    
  };
  const togglePublish = (id, publishBool) =>{
      const toggle = !publishBool

      firebase
      .firestore()
      .collection("testimonials")
      .doc(id)
      .update({publish:toggle});
  }
  
  const addRecipes = e => {

    e.preventDefault();    

    firebase
    .firestore()
    .collection("recipes")
    .add({
      recipe_name: recipe.name,
      ingredients: recipe.ingredients,
      directions: recipe.directions,
      video_link: recipe.video_link,      
      image_url: recipe.image_url,      
      
    })
    .then(() => setRecipe(initialRecipeState));
    
  }

  const addMenu = e => {

    e.preventDefault();    

    firebase
    .firestore()
    .collection("customMenus")
    .add({
      title: newCustomMenu.title,
      menu: newCustomMenu.menu,
    })
    .then(() => {
      // Reset custom menu form state
      setNewCustomMenu({ title: '', menu: [] });
      setNewMenuItem({ name: '', notes: '', type: '' });
      // Fetch updated menus
      GetMenus().then(updatedMenus => {
          // Assuming you have a state setter for Menus, like setMenus
          Menus = updatedMenus;
      });
  })
  .catch(error => {
      console.error("Error adding custom menu:", error);
  });
  }

  const handleDeleteItem = (type, menuId, itemIndex) => {
    // Get a reference to the specific menu document in Firebase
    const menuRef = firebase.firestore().collection("customMenus").doc(menuId);
    
    menuRef.get().then(doc => {
      if (doc.exists) {
        // Find the category type in the menu array
        const updatedMenu = doc.data().menu.map(category => {
          if (category.type === type) {
            return {
              ...category,
              menu: category.menu.filter((_, index) => index !== itemIndex),
            };
          }
          return category;
        });
  
        // Update the menu document with the new menu array
        menuRef.update({ menu: updatedMenu }).then(() => {
          console.log("Menu item deleted successfully!");
        }).catch(error => {
          console.error("Error deleting menu item: ", error);
        });
      }
    }).catch(error => {
      console.log("Error getting document:", error);
    });

  };
  
const handleSaveEdit = (type, menuId, itemIndex, newName) => {
    const menuRef = firebase.firestore().collection("customMenus").doc(menuId);

    menuRef.get().then(doc => {
      if (doc.exists) {
        // Find the category type in the menu array
        const updatedMenu = doc.data().menu.map(category => {
          if (category.type === type) {
            // Update the specific item's name
            let updatedItems = [...category.menu];
            updatedItems[itemIndex] = { ...updatedItems[itemIndex], name: newName };
            return {
              ...category,
              menu: updatedItems,
            };
          }
          return category;
        });

        // Update the menu document with the new menu array
        menuRef.update({ menu: updatedMenu }).then(() => {
          setEditingItemIndex(null);
          setEditedName(null);
          setEditingItemId(null);
          setEditedItemName(null);
          console.log("Menu item updated successfully!");
        }).catch(error => {
          console.error("Error updating menu item: ", error);
        });
      }
    }).catch(error => {
      console.log("Error getting document:", error);
    });

};

const handleSaveNewItem = (type, menuId) => {
  if (newItemName.trim().length > 0) {
    // Get a reference to the specific menu document in Firebase
    const menuRef = firebase.firestore().collection("customMenus").doc(menuId);

    menuRef.get().then((doc) => {
      if (doc.exists) {
        const updatedMenu = doc.data().menu.map(category => {
          if (category.type === type) {
            return {
              ...category,
              menu: [...category.menu, { name: newItemName, notes: "" }] // Assuming you want to add a name with empty notes
            };
          }
          return category;
        });

        menuRef.update({ menu: updatedMenu })
          .then(() => {
            console.log("New menu item added successfully!");
            setShowAddNewItem(false); // Hide the input field after saving
            setNewItemName(''); // Reset the input field
          }).catch(error => {
            console.error("Error adding new menu item: ", error);
          });
      }
    }).catch(error => {
      console.log("Error getting document:", error);
    });
  }
};
  
  const handleAddMenuItem = () => {
    console.log(newCustomMenu)
    const typeExists = newCustomMenu.menu.some(section => section.type === newMenuItem.type);

    if (typeExists) {
        // Add to an existing category
        setNewCustomMenu(prevMenu => ({
            ...prevMenu,
            menu: prevMenu.menu.map(section => 
                section.type === newMenuItem.type 
                    ? { ...section, menu: [...section.menu, { name: newMenuItem.name, notes: newMenuItem.notes }] } 
                    : section
            )
        }));
    } else {
        // Add a new type
        setNewCustomMenu(prevMenu => ({
            ...prevMenu,
            menu: [
                ...prevMenu.menu, 
                { type: newMenuItem.type, menu: [{ name: newMenuItem.name, notes: newMenuItem.notes }] }
            ]
        }));
    }

    // Reset newMenuItem
    setNewMenuItem({ name: '', notes: '', type:newMenuItem.type});
  };

  const onFileRecipeChange = async(e) => {
    const file = e.target.files[0]
    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(file.name)
    await fileRef.put(file)
    setRecipe({...recipe,image_url: await fileRef.getDownloadURL()})

  }

  const onFileHomeImageChange = async(e,id) => {

    const file = e.target.files[0]

    const storageRef = firebase.storage().ref()
    const fileRef = storageRef.child(file.name)
    await fileRef.put(file)

    const homeImageUrl = await fileRef.getDownloadURL()


    firebase
    .firestore()
    .collection("homeImages")
    .doc(id)
    .update ({
      image_url: homeImageUrl,      
    })

  }
  const addIngredients = (index,e) => {
    let obj = recipe.ingredients.slice()
    obj[index] = e
    setRecipe({...recipe,ingredients:obj})    
  }

  const addDirections = (index,e) => {
    let obj = recipe.directions.slice()
    obj[index] = e
    setRecipe({...recipe,directions:obj})    
  }

  const addCustomMenu = () => {
    setCustomMenus([...customMenus, newCustomMenu]);
    setNewCustomMenu({ name: '', category: '', items: [] }); // Reset the form
  };

  // Function to display custom menus

  const DisplayCustomMenus = () => {
    const [activeMenuTitle, setActiveMenuTitle] = useState(null);
    const [activeMenuType, setActiveMenuType] = useState(null);

    const copyToClipboard = (menuTitle) => {
      const url = `https://darkhorsegrillandcatering.com/menu/?title=${encodeURIComponent(menuTitle)}`;
      navigator.clipboard.writeText(url).then(() => {
        alert('Custom menu link copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    };

    const toggleMenuType = (type, menuId) => {
        setActiveMenuType(activeMenuType === type ? null : type);
    };

    const toggleMenuTitle = (title) => {
      setActiveMenuTitle(activeMenuTitle === title ? null : title);
  };

    return <div>
      {Menus.length > 0 && 
      <div className="custom-menu">
          <h1>Menus</h1>
          <p>click on a menu to edit 👇🏽👇🏽👇🏽</p>
              {Menus.map((menu, index) => (
                  <div className="custom-menu-items" key={index}>
                    
                      <button 
                        style={{
                          color: activeMenuTitle === menu.title ? "black" : "white",
                          fontWeight: activeMenuTitle === menu.title ? "900" : "normal",
                          width: "80%",
                          backgroundColor: "rgb(189 141 79)"
                          
                        }}
                      className="menuTitles" onClick={() => toggleMenuTitle(menu.title)}>
                          {menu.title}
                      </button>
                      
                      <button 
                      style={{
                        width: "60%",
                        backgroundColor: "#a59889"
                      }}
                       onClick={() => copyToClipboard(menu.title)}>
                        Copy Custom Menu Link
                      </button>

                      {activeMenuTitle === menu.title && menu.menu.map((item, idx) => (
                          <div key={idx}>

                            <h1 onClick={() => toggleMenuType(item.type + menu.id)} style={{listStyle: "numeric", textAlign: "center", color: "#fff", cursor: "pointer"}}>
                                {item.type}
                            </h1>

                              {activeMenuType === item.type + menu.id &&
                                  <div>
                                    {showAddNewItem ? (
                                      <div>
                                        <input
                                          type="text"
                                          value={newItemName}
                                          onChange={e => setNewItemName(e.target.value)}
                                          placeholder="New item name"
                                        />
                                        {newItemName.trim().length > 1 && (
                                          <button onClick={() => handleSaveNewItem(item.type, menu.id)}>
                                            Save
                                          </button>
                                        )}
                                        <button onClick={handleAddNewItemToggle}>
                                          Cancel
                                        </button>
                                      </div>
                                    ) : (
                                      <button 
                                      style={{background: "white", color: "black", fontWeight: "bold", marginBottom:"50px"}}
                                      onClick={handleAddNewItemToggle}>
                                        Add New Item
                                      </button>
                                    )}
                                  </div>
                                  }
                          
                              {activeMenuType === item.type + menu.id && item.menu.map((menuItem, menuIndex) => (
                                <div 
                                style={{borderBottom:"black solid 7px"}}
                                key={menuIndex}>
                                    {editingItemId === `${menu.id}-${item.type}-${menuIndex}`  ? (
                                      <div>
                                        <input 
                                          type="text" 
                                          value={editedItemName} 
                                          placeholder={menuItem.name}
                                          onChange={e => setEditedItemName(e.target.value)} 
                                          />
                                        <button onClick={() => handleSaveEdit(item.type, menu.id, menuIndex, editedItemName)}>Save</button>
                                          <button onClick={() => {
                                            setEditingItemId(null);
                                            setEditedItemName('');
                                          }}>Cancel</button>
                                            </div>
                                      ) : (
                                              <div 
                                                style={{textAlign: "right"}}
                                              >
                                                  <span
                                                    style={{fontSize: "27px", marginRight: "10px"}}
                                                  >{menuItem.name}</span>
                                                  <button 
                                                    className="menu-item-options"
                                                    onClick={() => handleEditItem(menu.id, item.type, menuIndex)}>Edit</button>
                                                  <button
                                                    className="menu-item-options"
                                                    style={{color: "red"}}
                                                  onClick={() => handleDeleteItem(item.type,menu.id, menuIndex)}>Delete</button>
                                              </div>
                                    )}

                                    
                                  </div>
                              ))}

                          </div>
                      ))}

                      {activeMenuTitle === menu.title &&
                      <div>
                          <button
                          style={{background: "red", marginTop: "45px", color: "black", fontWeight: "bold"}}
                          onClick={() => {
                            setMenuToDelete(menu.id);
                            setShowDeleteConfirmation(true);
                          }}>Delete This Menu</button>

                            {showDeleteConfirmation && menuToDelete === menu.id && (
                              <div>
                                <p>Are you sure you want to delete this menu?</p>
                                <button onClick={() => deleteMenu(menu.id)}>Yes</button>
                                <button onClick={() => {
                                  setMenuToDelete(null);
                                  setShowDeleteConfirmation(false);
                                }}>No</button>
                              </div>
                            )}
                        </div>
                      }


                  </div>
              ))}
          </div>
        }
      </div>
  };
  const onAddOtherItem = () => {
    
    const direction = recipe.directions
    
    direction.push({[direction.length+1]:""})
    setRecipe({...recipe,directions:direction})
  }

  const deleteRecipes = id => {

    firebase
    .firestore()
    .collection("recipes")
    .doc(id)
    .delete();

  };

  const deleteTestimonial = id => {
    
        firebase
        .firestore()
        .collection("testimonials")
        .doc(id)
        .delete();
    
  };

  const deleteEvent = id => {
    
        firebase
        .firestore()
        .collection("events")
        .doc(id)
        .delete();
    
  };

  const deleteImage = (imgUrl,id) => {

    const storageRef = firebase.storage().refFromURL(imgUrl)

    storageRef.delete().then(function(){
      firebase
      .firestore()
      .collection("homeImages")
      .doc(id)
      .update ({
        image_url: "",      
      })
    }).catch(function(error){
      console.log(error,"errors")
    })

  }

  const onAddItem = () => {

    const ingredient = recipe.ingredients
    
    ingredient.push({[ingredient.length+1]:""})
    setRecipe({...recipe,ingredients:ingredient})
  };

  const auth = () => {
    if (typeof window !== 'undefined') {
      const admin = window.localStorage.getItem("adminUser")
      if(admin==false || admin==null){
        navigate(`/admin_login`)
      }
    } else {
        console.log('we are running on the server');
    }
  }

  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(previousValue => !previousValue);
  const dropdownOptions = modal ? 'show_recepie' : '';

  const checkAdminAuth = auth()
  const animationTextFirestore = GetAnimations()  
  const events = GetEvents()
  const testimonials = GetTestimonial()
  const recipes = GetRecipes()
  const homeImages = GetHomeImageUrls()
  let Menus = GetMenus()

  return (
      
    
      <div className="admin_page">

        <div className="home_text_animation">
          <p className="data_title">Home Screen Text Animations</p>
          <ul >
            {animationTextFirestore.map(animation=>(
              <li key={animation.id}>
                  <p className="tg-ycr8">{animation.animation}</p>
                  <div className="tg-6hdc">
                    <button className="admin_buttons" onClick={()=>editAnimationTextItem(animation)}>Edit</button>
                  </div>
                </li>
              ))}
            </ul>
            <form style={{textAlign:"center", padding:"1px"}} onSubmit={onTextAnimationSubmit}>
              <input className="text_animation_input" type="text" name="animation" placeholder={item.animation} onChange={onAnimationTextChange}/>
              {item.id ?
                <button className="admin_buttons">Change Animation</button>
              :
                <p style={{textAlign:"center"}}>Choose Animation to update</p>
              }
            </form>
        </div>
        
        <div className="custom_menus">

          <div className="display_custom_menus">
              {DisplayCustomMenus()}
          </div>

        <div className="create_custom_menus">
          <h2 style={{
            textDecoration:"underline", margin:"5px 0px"
          }}>Create Custom Menu</h2>

          <form onSubmit={addMenu}>
              <p
              style={{color:"white", fontSize:"1.2rem", textAlign:"center"}}
              >Custom Menu Title:</p>
              <input
                  type="text"
                  placeholder="Custom Menu Title"
                  value={newCustomMenu.title}
                  onChange={e => setNewCustomMenu({ ...newCustomMenu, title: e.target.value })}
              />
              <p
                style={{color:"white", fontSize:"1.2rem", textAlign:"center"}}
              >Food Menu Category:</p>
              <select
                  value={newMenuItem.type}
                  onChange={e => setNewMenuItem({ ...newMenuItem, type: e.target.value })}
              >
                <option value="">Menu Category</option>
                <option value="entrees">Entrees</option>
                <option value="sides">Sides</option>
                <option value="desserts">Desserts</option>
                <option value="appetizers">Appetizers</option>
                <option value="main course">Main Course</option>
                <option value="starters">Starters</option>
            </select>
            <p
                style={{color:"white", fontSize:"1.2rem", textAlign:"center"}}
              >Menu Item Name:</p>
            <input
                type="text"
                placeholder="Menu Item Name"
                value={newMenuItem.name}
                onChange={e => setNewMenuItem({ ...newMenuItem, name: e.target.value })}
            />
            <p
                style={{color:"white", fontSize:"1.2rem", textAlign:"center"}}
              >Notes:</p>
            <textarea
                placeholder="Notes (optional)"
                value={newMenuItem.notes}
                onChange={e => setNewMenuItem({ ...newMenuItem, notes: e.target.value })}
            />
            {newMenuItem.type && newMenuItem.name &&
              <button style={{backgroundColor:"white", display:"block", margin:"auto"}} type="button" onClick={handleAddMenuItem}>Add Item to {newMenuItem.type}</button>
            }

            {newCustomMenu.title && newCustomMenu.menu.length > 0 && <button style={{backgroundColor:"white", color:"black", display:"block", margin:"25px auto", cursor:"pointer", fontWeight:"bold"}}  type="submit">Publish Menu Online</button>}

          </form>
          </div>
          <div className="preview-custom-menu"
            style={{
              padding: "10px",
              backgroundColor:"red",
              color:"white",
          }}
          >
        
            {newCustomMenu.title && <h3 style={{color:"#fff"}}>Menu Preview:</h3>}
            {newCustomMenu.title && <h4>Menu: {newCustomMenu.title}</h4>}
            {newCustomMenu.menu.length > 0 && (
              <ul>
                {newCustomMenu.menu.map((item, index) => (
                    <li key={index}>
                        <p style={{textDecoration:"underline", fontSize:"20px"}}><strong>Type:</strong> {item.type}</p>
                        <ul>
                            {item.menu.map((menuItem, idx) => (
                                <li key={idx}>
                                    <strong>Name:</strong> {menuItem.name}
                                    {menuItem.notes && (
                                        <>
                                            , <strong>Notes:</strong> {menuItem.notes}
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </li>
                ))}
              </ul>
              )}

              </div>
        <div/>

          

        </div>

        <div className="event">
          <p className="data_title">Events</p>
            <div className="scroll">
              <table className="event_table">
              <tr>
                <th>Event Name</th>
                <th>Event Location</th>
                <th>Additional Comments</th>
                <th>Event Date</th>
                <th>Option</th>
              </tr>
              {events.map(event=>(
                <tr key={event.id}>
                    <td >{event.eventName}</td>
                    <td >{event.where}</td>
                    <td >{event.additionalComment}</td>
                    <td >{event.date}</td>
                    <td >
                      <button style={{background:"red",color:"white"}} onClick={()=>deleteEvent(event.id)}>Delete</button>
                    </td>
                  </tr>
                ))}
              </table>
            </div>

          <div className="addeventform">
              <p>Add new Event</p>
              <form onSubmit={addEvent}>
                 <input className="event_input" type="text" value={eventName} name="eventName" placeholder="Event Name" onChange={e => setEventName(e.currentTarget.value)}/>
                <input className="event_input" type="text" value={eventLocation} name="eventLocation" placeholder="Event Location" onChange={e => setEventLocation(e.currentTarget.value)}/>
                <input className="event_input" type="text" value={eventCity} name="eventCity" placeholder="Event city (example: Aurora, IL)" onChange={e => setEventCity(e.currentTarget.value)}/>
                <textarea className="event_input" type="text" value={eventAdditionalComments} name="eventDate" placeholder="Additional Comments" required onChange={e => setEventAdditionalComments(e.currentTarget.value)}></textarea>
                <input className="event_input" type="date" value={eventDate} name="eventDate" type="date" placeholder="dd/mm/yyyy" pattern="(^(((0[1-9]|1[0-9]|2[0-8])[\/](0[1-9]|1[012]))|((29|30|31)[\/](0[13578]|1[02]))|((29|30)[\/](0[4,6,9]|11)))[\/](19|[2-9][0-9])\d\d$)|(^29[\/]02[\/](19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)$)" required onChange={e => setEventDate(e.currentTarget.value)}/>
                <button className="admin_buttons addeventbutton">Add Event</button>
              </form> 
            </div>

          </div>

        <div className="tenstimonial_input">
          <p className="data_title">Tenstimonials</p>
          <ul className="testimonials">
            {testimonials.map(testimonial=>(
              <li style={testimonial.publish == true ? {background:"#97dc97"} : { background:"#f5a2a2" }} className="data_card" key={testimonial.id}>
                <p className="testimonial_name">{testimonial.username}</p>
                <p className="testimonial">{testimonial.testimonial}</p>
                <div className="tg-6hdc">
                  {
                    testimonial.publish == true ? (
                      <button className="admin_buttons" onClick={()=>togglePublish(testimonial.id,testimonial.publish)}>Hide From Website</button>
                    ):
                    (
                      <button className="admin_buttons" onClick={()=>togglePublish(testimonial.id,testimonial.publish)}>Show On Website</button>
                    )
                  }
                  <button className="admin_buttons" style={{background:"red"}} onClick={()=>deleteTestimonial(testimonial.id)}>Delete</button>
                </div>
              </li>
              ))}
            </ul>

            <form className="testimonials-form" onSubmit={addTestimonials}>
              <h1>Add Testimonial</h1>

              <input 
                className="testimonial_input" 
                type="text" 
                name="username" 
                placeholder="Name" 
                onChange={e => setTestimonialUsername(e.currentTarget.value)}
              />
              <textarea 
                className="testimonial_input" 
                name="testimonial" 
                placeholder="Testimonial" 
                onChange={e => setTestimonial(e.currentTarget.value)}
              />
              <input 
                className="testimonial_input" 
                type="date" 
                name="date" 
                placeholder="dd/mm/yyyy" 
                onChange={e => setTestimonialDate(e.currentTarget.value)}
              />
              <button className="admin_buttons">Add Testimonial</button>
            </form>

        </div>

        <div className="recipes_input">
          <p className="data_title">Recipes</p>
          <ul>
            {recipes.map((recipe,index )=>(
              <div key={recipe.id}>
              <h2 className={`recipe_name ${recipe.id}` } onClick={()=>setCss(recipe.id)}>{recipe.recipe_name}</h2>
              
              <li className={`recipe_data_card ${dropdownOptions}`} style={recipe.id === dropDown.id ? {height:dropDown.height} : {}} >

                {recipe.ingredients.map((ingredient,index) =>(
                  <div key={index}>
                    <p >{index+1}) {ingredient}</p>
                  </div>
                ))}

                {recipe.directions.map((direction,index) =>(
                  <div key={index}>
                    <p>{direction}</p>
                  </div>
                ))}
                <img className="recipe_image" src={recipe.image_url} alt="Recipe Image"/>

                  <div className="tg-6hdc">
                    <button className="admin_buttons" onClick={()=>deleteRecipes(recipe.id)}>Delete</button>
                  </div>
                </li>
              </div>
              ))}
          </ul>
          

            <form className="add-recipe-form" onSubmit={addRecipes}>
              <h2>Recipe Name</h2>                              
                <input className="recipe_input" type="text" value={recipe.name} name="name" placeholder="Recipe Name" onChange={e => setRecipe({...recipe, name: e.currentTarget.value})}/>
              <h3>Add an Ingridient</h3>
              {recipe.ingredients.map((ingridient,index)=>(
                <div key={index}>
                  <label>ingridient {index+1}</label>
                  <input id={index} className="recipe_input" name="ingridients" type="text" placeholder="Type Ingridient here" onChange={(e)=> addIngredients(index, e.currentTarget.value)}/>
                </div>
              ))}

              <input type="button" className="addOns" value="Add More Ingredients" onClick={()=>onAddItem()}/>

              <h3>Recipe Directions</h3>
              {recipe.directions.map((direction,index)=>(
                <div key={index}>
                  <label>Step {index+1}</label>
                  <textarea id={index} className="recipe_input"  name="direction" type="text" placeholder="Add new direction" onChange={(e)=> addDirections(index, e.currentTarget.value)}></textarea>
                </div>
              ))}
              <input type="button" className="addOns" value="Add More Steps" onClick={()=>onAddOtherItem()}/>
              <label>Link to youtube video or other platforms</label>
              <input type="text" className="video_input" name="video_link" value={recipe.video_link} placeholder="Video Link" onChange={e => setRecipe({...recipe,video_link:e.currentTarget.value})}/>
              <label>Recipe Image (required)</label>
              <input type="file" className="image_upload" name="recipe_name" palceholder="Recipe Image" onChange={onFileRecipeChange}/>
              {recipe.name && recipe.image_url && recipe.ingredients.length > 0 && <button className="admin_buttons">Publish Recipe Online</button> }
            </form>

              <div className="home-image-container">
                <form> 
                  <p className="data_title">Home Page Menu Images</p>

                  {homeImages.map(url=>
                    <div key={url.id} className="home-images" >

                      { url.image_url === "" && <h2>No Image</h2> }
                      { url.image_url != "" && 
                        <div >
                          <img src={url.image_url} /> <span onClick={e=>deleteImage(url.image_url, url.id)} className="delete-image"> <Image filename="close.png"/> </span>
                        </div>
                      }

                      <input type="file" className="image_upload" name="recipe_name" palceholder="Recipe Image" onChange={e => onFileHomeImageChange(e, url.id)} />
                    </div>
                  )}
                </form>
            </div>
            
        </div>

      </div>
      
    )
}

export default Admin